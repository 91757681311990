import request from '../../utils/request';

const getBenchmark = () => {
  return request({
    url: '/api/report/benchmark',
    method: 'POST',
    private: true,
  });
};
const getCuali = () => {
  return request({
    url: '/api/report/cuali',
    method: 'POST',
    private: true,
  });
};
const getCuanti = () => {
  return request({
    url: '/api/report/cuanti',
    method: 'POST',
    private: true,
  });
};

const ReportService = {
  getBenchmark,
  getCuali,
  getCuanti,
};

export default ReportService;
