import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import routes from '../routes';
import RouteWrapper from '../routes/RouteWrapper';
import '../assets/index.less';
import AppContextProvider from './AppContext';
import ParamsProvider from './ParamsContext';

function App() {
  return (
    <div className="App">
      <Router>
        <AppContextProvider>
          <ParamsProvider>
            <Switch>
              {routes.map((route, index) => {
                const key = `route_${index}`;
                return <RouteWrapper key={key} {...route} />;
              })}
            </Switch>
          </ParamsProvider>
        </AppContextProvider>
      </Router>
    </div>
  );
}

export default App;
