import request from '../../utils/request';

const getParams = (endpoint, payload) =>
  request({
    url: `api/${endpoint}`,
    method: 'GET',
    params: payload,
    private: true,
  });

export default {
  getParams,
};
