import { Layout } from 'antd';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../app/AppContext';
import DropdownMenu from './DropdownMenu';
import './styles.less';
import LogoPNG from '../../../images/Logo.png';

const { Header } = Layout;

const LayoutHeader = () => {
  const { user } = useContext(AppContext);

  return (
    <Header className="layout-header">
      <Link to="/">
        <img width={75} height={40} src={LogoPNG} alt="Imagen logo otic" />
      </Link>

      <div className="right-section">
        <span>{user.name}</span>

        <DropdownMenu />
      </div>
    </Header>
  );
};

export default LayoutHeader;
