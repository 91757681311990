import { HomeOutlined } from "@ant-design/icons";
import MarketIntelligenceReports from "../domain/Reports/MarketIntelligence";
import Benchmark from "../domain/Reports/MarketIntelligence/Benchmark";
import OTECCuali from "../domain/Reports/MarketIntelligence/OTECCuali";
import OTECCuanti from "../domain/Reports/MarketIntelligence/OTECCuanti";

const routes = [
  // PRIVATE

  {
    path: "/",
    component: MarketIntelligenceReports,
    exact: true,
    title: "Reportes Inteligencia de mercado",
    breadcrumb: "Reports",
    icon: <HomeOutlined />,
    roleAccess: "all",
    showInMenu: true,
  },

  {
    path: "/reportes/benchmark",
    component: Benchmark,
    exact: true,
    title: "Reporte Benchmark",
    breadcrumb: "Reports",
    icon: <HomeOutlined />,
    roleAccess: "all",
    showInMenu: true,
  },
  {
    path: "/reportes/otec-cuanti",
    component: OTECCuanti,
    exact: true,
    title: "Reporte Otec Cuantitativo",
    breadcrumb: "Reports",
    icon: <HomeOutlined />,
    roleAccess: "all",
    showInMenu: true,
  },
  {
    path: "/reportes/otec-cuali",
    component: OTECCuali,
    exact: true,
    title: "Reporte Otec Cualitativo",
    breadcrumb: "Reports",
    icon: <HomeOutlined />,
    roleAccess: "all",
    showInMenu: true,
  },
];

export default routes;
