import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { getAuthorization, removeToken } from "../utils/auth";
import { parseJwt } from "../utils/token";

import UserService from "../service/UserService";
import cookie from "js-cookie";

import { clean } from "rut.js";

export const AppContext = React.createContext();

const TIME_DATASET_INTERVAL = 1000 * 60 * 5;
class AppContextProvider extends Component {
  getSystemFunctions() {
    UserService.getSystemFunctions({ system: "IM" })
      .then(({ data }) => {
        const { result } = data;

        const {
          enterprises,
          holdings,
          assignAllEnterprises,
          systems,
          enterprise,
        } = result;
        const formatedRutEnterprises = enterprises
          .filter((e) => (clean(e.rut) ? true : false))
          .map((e) => ({ ...e, rut: Number(clean(e.rut).slice(0, -1)) }));

        const formatedEnterprise = enterprise
          ? Number(clean(enterprise.rut).slice(0, -1))
          : null;
        this.setState((prev) => ({
          functions: systems[0].functions,
          user: {
            enterprises: formatedRutEnterprises,
            holdings,
            assignAllEnterprises,
            enterprise: formatedEnterprise,
            ...prev.user,
          },
        }));
      })
      .finally(() => {
        this.setState(() => ({ isAllDataLoaded: true }));
      });
  }

  userActions = {
    setUser: (user) => {
      this.setState({
        user,
      });

      if (user) {
        sessionStorage.setItem("otic.user", JSON.stringify(user));

        this.setState({ isDatasetLoading: true });

        this.getSystemFunctions();
        // TODO: FETCH INITIAL DATA
      }
    },
    cleanUser: () => {
      this.setState({
        user: {
          name: "",
          email: "",
          username: "",
          rut: undefined,
          id: undefined,
        },
      });
      clearInterval(this.state.datasetInterval);
      this.setState({
        isTokenRefreshing: false,
        interval: null,
      });
    },
  };

  constructor() {
    super();

    this.state = {
      user: {
        name: "",
        holdings: [],
        enterprises: [],
      },

      datasetInterval: null,
      isAllDataLoaded: false,
    };
  }

  componentDidMount() {
    let authUser = sessionStorage.getItem("otic.user");

    if (authUser !== null) {
      this.setState({ user: JSON.parse(authUser) });
    } else if (getAuthorization()) {
      const tokenPayload = parseJwt(cookie.get("otic.token.private"));

      const user = {
        name: tokenPayload.name,
        username: tokenPayload.preferred_username,
        email: tokenPayload.email,
        rut: tokenPayload.rut,
        id: tokenPayload.sub,
      };
      this.userActions.setUser(user);
      authUser = user;
    }

    if (authUser && getAuthorization()) {
      this.getSystemFunctions();
    } else {
      this.setState(() => ({ isAllDataLoaded: true }));
      removeToken();
    }
  }

  render() {
    const { children } = this.props;
    const { user, isAllDataLoaded, functions } = this.state;
    return (
      <AppContext.Provider
        value={{
          user: { ...user, actions: this.userActions },
          isAllDataLoaded,
          functions,
        }}
      >
        {children}
      </AppContext.Provider>
    );
  }
}

AppContextProvider.propTypes = {
  history: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default withRouter(AppContextProvider);
