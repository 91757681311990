import moment from 'moment';
import cookies from 'js-cookie';
import { parseJwt } from './token';

const saveToken = (token, key) => {
  if (token) {
    cookies.set(key || 'otic.token.private', token, { expires: 1 });
  }
};

const removeToken = () => {
  cookies.remove('otic.token.private');
  cookies.remove('otic.user');
};

const getToken = () => {
  return cookies.get('otic.token.private');
};

const getAuthorization = () => {
  return cookies.get('otic.token.private') ? `Bearer ${cookies.get('otic.token.private')}` : false;
};

const isTokenValid = (token) => {
  const data = parseJwt(token);

  if (data !== undefined) {
    return moment.unix(data.exp).isAfter(new Date());
  }

  return false;
};
const isLogin = () => {
  const token = getToken();
  const isValid = isTokenValid(token);
  return isValid;
};

export { saveToken, getAuthorization, removeToken, getToken, isLogin, isTokenValid };
