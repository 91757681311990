import { Button, Col, message, notification, Row } from "antd";
import { PowerBIEmbed } from "powerbi-client-react";

import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { AppContext } from "../../../../app/AppContext";

import OutlineArrowCircleLeft from "../../../../assets/icons/OutlineArrowCircleLeft/icon";
import ReportService from "../../../../service/ReportService";

import "./style.less";

const Benchmark = () => {
  const { isAllDataLoaded, user } = useContext(AppContext);
  const router = useHistory();
  const [report, setReport] = useState(null);
  const [embedConfig, setEmbedConfig] = useState({
    type: "report", // Supported types: report, dashboard, tile, visual and qna
    embedUrl: undefined,
    accessToken: undefined, // Keep as empty string, null or undefined
    settings: {
      panes: {
        filters: {
          expanded: false,
          visible: false,
        },
        pageNavigation: {
          visible: false,
        },
      },
    },
  });

  useEffect(() => {
    notification.info({
      description: (
        <>
          Estamos cargando la información,
          <br />
          esto podría demorar unos minutos
        </>
      ),
    });
  }, []);
  useEffect(() => {
    if (isAllDataLoaded) {
      ReportService.getBenchmark()
        .then(({ data }) => {
          const { result } = data;
          let filters = [];
          const values = [];
          if (user.enterprise) {
            values.push(user.enterprise);
          }
          values.push(...user.enterprises.map((e) => e.rut));
          if (!user.assignAllEnterprises) {
            filters = [
              {
                $schema: "http://powerbi.com/product/schema#basic",
                target: {
                  table: "Empresas",
                  column: "RUT_EMPRESA",
                },
                operator: "In",
                values,
                filterType: 1,
                requireSingleSelection: true,
              },
            ];
          }

          setEmbedConfig((values) => ({
            ...values,
            embedUrl: result.embedUrl,
            accessToken: result.accessToken,
            pageName: result.pageName,
            filters,
          }));
        })
        .catch((e) => {
          if (e.status === 401) {
            router.push("/");
          }
          message.error(
            "Ocurrio un error al cargar la información, intente mas tarde."
          );
        });
    }
  }, [isAllDataLoaded]);

  const handlePrintReport = async () => {
    if (report) {
      await report.print();
    }
  };
  return (
    <>
    <Row gutter={24}>
      <Col span={24}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <OutlineArrowCircleLeft style={{ marginRight: '6px' }} />
          <Link to="/" style={{ textAlign: 'center' }}>
            Volver atrás
          </Link>
        </div>
        <br />

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h2>
            <strong>Reporte Benchmark </strong>
          </h2>
          <Button type="primary" loading={!report} onClick={handlePrintReport}>
            Descargar reporte
          </Button>
        </div>
      </Col>
      {/* <Col span={24}>
      <Button type="primary" title="Descargar reporte" icon={<DownloadOutlined />} size="large" />
      </Col> */}
    </Row>

    <PowerBIEmbed
      embedConfig={embedConfig}
      cssClassName="report-style-class"
      getEmbeddedComponent={(embeddedReport) => setReport(embeddedReport)}
    />
  </>

  );
};

export default Benchmark;
