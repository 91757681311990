import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./styles.less";

const IconDot = ({ icon, size, position }) => {
  const [style, setStyle] = useState({
    width: `${size}px`,
    height: `${size}px`,
  });

  useEffect(() => {
    if (position) {
      let tempStyle = style;
      const { top, bottom, left, right } = position;
      if (top) {
        tempStyle = { ...tempStyle, top };
      }
      if (bottom) {
        tempStyle = { ...tempStyle, bottom };
      }
      if (left) {
        tempStyle = { ...tempStyle, left };
      }
      if (right) {
        tempStyle = { ...tempStyle, right };
      }

      setStyle(tempStyle);
    }
  }, [position]);
  return (
    <div className="icon-dot">
      {icon}

      <span className="color-dot" style={style} />
    </div>
  );
};

export default IconDot;

IconDot.propTypes = {
  icon: PropTypes.node.isRequired,
  size: PropTypes.number,
};
IconDot.defaultProps = {
  size: 34,
};
