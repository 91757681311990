import { Button, Card } from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.less';

const CardImageText = ({ onClick, enableLoading, imageComponent, title, text, buttonText }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onClickButton = async () => {
    try {
      if (enableLoading) {
        setIsLoading(true);
      }
      await onClick();
      return setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      return e;
    }
  };
  return (
    <Card hoverable className="card-image">
      <div className="parent-position">
        {imageComponent}
        <Card.Meta
          title={
            <h4>
              <strong>{title}</strong>
            </h4>
          }
          description={text}
        />
        <Button type="primary" block onClick={onClickButton} loading={isLoading}>
          {buttonText}
        </Button>
      </div>
    </Card>
  );
};

export default CardImageText;

CardImageText.propTypes = {
  onClick: PropTypes.func.isRequired,
  imageComponent: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  text: PropTypes.node.isRequired,
  enableLoading: PropTypes.bool,
  buttonText: PropTypes.string,
};

CardImageText.defaultProps = {
  enableLoading: false,
  buttonText: 'Ver más',
};
