import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import './style.less';

const ItemMenu = ({ name, icon, url, isActive }) => {
  const router = useHistory();
  return (
    <div
      onClick={() => router.push(url)}
      role="button"
      onFocus={() => {}}
      tabIndex={0}
      onKeyDown={() => {}}
      className={`item-menu${isActive ? ' active' : ''}`}
    >
      <small>
        {icon} {name}
      </small>
    </div>
  );
};

ItemMenu.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  url: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
};

ItemMenu.defaultProps = {
  isActive: false,
};

export default ItemMenu;
