import axios from 'axios';
import { getAuthorization } from './auth';
/**
 * Create an Axios Client with defaults
 */

const generateClientInstance = (isProfileAPI) => {
  const client = axios.create({
    baseURL: isProfileAPI ? process.env.REACT_APP_PROFILE_URL : process.env.REACT_APP_URL,
  });

  // Manejo de códigos de error del backend.
  client.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return client;
};

/**
 * Request Wrapper with default success/error actions
 */
const request = (options) => {
  const client = generateClientInstance(options.profileAPI);

  if (options.private) {
    client.defaults.headers.common.Authorization = getAuthorization();
  } else {
    delete client.defaults.headers.common.Authorization;
  }

  const onSuccess = (response) => {
    return response;
  };

  const onError = (error) => {
    return Promise.reject(error.response || error.message);
  };

  return client(options).then(onSuccess).catch(onError);
};

export default request;
