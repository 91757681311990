import { BarChartOutlined, DollarOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";

import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { AppContext } from "../../../app/AppContext";
import MagnifyingGlassIcon from "../../../assets/icons/MagnifyingGlass";
import OutlineArrowCircleLeftIcon from "../../../assets/icons/OutlineArrowCircleLeft";
import CardImageText from "../../../components/CardImageText";
import IconDot from "../../../components/IconDot";
import CondecorationIcon from "../../../assets/icons/Condecoration";

const MarketIntelligenceReports = () => {
  const { functions = [] } = useContext(AppContext);

  const router = useHistory();

  const reportList = [
    {
      key: "BENCHMARK",
      component: (
        <CardImageText
          title="Benchmark"
          buttonText="Ver reporte"
          onClick={() => {
            router.push("/reportes/benchmark");
          }}
          imageComponent={
            <IconDot
              icon={<MagnifyingGlassIcon />}
              size={34}
              position={{ top: -5, left: 15 }}
            />
          }
          text="Revisa aquí los principales indicadores de tú empresa comparados con el mercado."
        />
      ),
    },
    {
      key: "OTEC_CUANTI",
      component: (
        <CardImageText
          title="Ranking OTEC Cuanti"
          buttonText="Ver reporte"
          imageComponent={
            <IconDot
              icon={<CondecorationIcon />}
              size={34}
              position={{ top: -5, left: 4 }}
            />
          }
          onClick={() => {
            router.push("/reportes/otec-cuanti");
          }}
          text="Revisa aquí los OTEC con mayor participación en el mercado."
        />
      ),
    },
    {
      key: "OTEC_CUALI",
      component: (
        <CardImageText
          title="Ranking OTEC Cuali"
          buttonText="Ver reporte"
          onClick={() => {
            router.push("/reportes/otec-cuali");
          }}
          imageComponent={
            <IconDot
              icon={<CondecorationIcon />}
              size={34}
              position={{ top: -5, left: 4 }}
            />
          }
          text="Revisa aquí los OTEC con las mejores evaluaciones."
        />
      ),
    },
  ];

  return (
    <Row gutter={12} style={{ width: "100%" }}>
      <Col span={24}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            marginBottom: "30px",
          }}
        >
          <OutlineArrowCircleLeftIcon style={{ marginRight: "6px" }} />
          <Link
            to="/"
            style={{ textAlign: "center" }}
            onClick={() => {
              window.top.postMessage("goBackHome", "*");
            }}
          >
            Volver atrás
          </Link>
        </div>
      </Col>

      <Col xs={24} lg={{ span: 9 }}>
        <h2>
          {" "}
          <strong>Inteligencia de Mercado</strong>
        </h2>
        <p>
          Revisa aquí los principales indicadores de tu uso de la franquicia
          tributaria y de la industria, el ranking de OTEC y la recomendación de
          cursos.
        </p>
      </Col>

      <Col xs={24} lg={{ span: 15 }}>
        <Row gutter={6}>
          {functions.map((f) => {
            const report = reportList.find((r) => r.key === f.key);

            return (
              <Col
                xs={24}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                style={{ marginBottom: "75px" }}
              >
                {report?.component}
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
};

export default MarketIntelligenceReports;
