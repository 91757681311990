import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { isLogin } from '../utils/auth';
import PrivateLayout from '../components/layout/Layout';
import NotActive from '../domain/NotActive';

const RouteWrapper = ({ component: Component, path, restricted = false, isPublic = false }) => {
  // Window's title

  useEffect(() => {
    if (!isLogin()) {
      window.top.postMessage('notActiveToken', '*');
    }
  });
  return isPublic ? (
    isLogin() && restricted ? (
      <Redirect to="/" />
    ) : (
      <Route path={path} render={(props) => <Component {...props} />} />
    )
  ) : isLogin() ? (
    <Route
      path={path}
      render={(props) => (
        <PrivateLayout {...props}>
          <Component {...props} />
        </PrivateLayout>
      )}
    />
  ) : (
    <Route
      path={path}
      render={(props) => (
        <PrivateLayout {...props}>
          <NotActive />
        </PrivateLayout>
      )}
    />
  );
};

RouteWrapper.defaultProps = {
  restricted: false,
  isPublic: false,
  withHeader: false,
};

RouteWrapper.propTypes = {
  component: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  restricted: PropTypes.bool,
  isPublic: PropTypes.bool,
  withHeader: PropTypes.bool,
};

export default RouteWrapper;
