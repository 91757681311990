import React from 'react';
import SadFaceIcon from '../../assets/icons/SadFace';

const NotActive = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <SadFaceIcon />

      <h4>
        <strong>¡Vaya! Algo salió mal</strong>
      </h4>
      <p>
        No tienes acceso a esta página o posiblemente sesión haya expirado. Por favor inicia sesión
        nuevamente.
      </p>
    </div>
  );
};

export default NotActive;
