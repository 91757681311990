import { Layout, Menu, Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import LayoutHeader from '../Header';
import './styles.less';
import Footer from '../Footer';
import SideMenu from '../SideMenu';

const variants = {
  hidden: { opacity: 0, x: -200, y: 0 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, x: 0, y: -100 },
};
const { Content, Sider } = Layout;
const PrivateLayout = ({ children }) => {
  const [isFullWidth, setIsFullWidth] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location && location.pathname && location.pathname.includes('/reportes')) {
      setIsFullWidth(true);
    } else {
      setIsFullWidth(false);
    }
  }, [location.pathname]);
  return (
    <Layout>
      <Content>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <motion.main
            initial="hidden"
            animate="enter"
            exit="exit"
            variants={variants}
            transition={{ type: 'linear' }}
            className={`site-content ${isFullWidth ? 'full-width' : 'limited-width'}`}
          >
            {children}
          </motion.main>
        </div>
      </Content>
    </Layout>
  );
};

PrivateLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateLayout;
