import React, { createContext, useState } from 'react';
import { PropTypes } from 'prop-types';
import ParamsService from '../service/ParamsService';
import endpoints from '../service/ParamsService/endpoints';

export const ParamsContext = createContext();

const initializeState = () => {
  const params = {};

  Object.values(endpoints).forEach((paramEndpoint) => {
    params[paramEndpoint] = {
      items: [],
      isLoading: false,
      hasBeenRequested: false,
    };
  });

  return params;
};

export default function ParamsProvider({ children }) {
  const [params, setParams] = useState(initializeState);

  const requestParam = (key, payload) => {
    const { isLoading, hasBeenRequested } = params[key];
    if (hasBeenRequested || isLoading) return;

    setParams((prevParams) => ({
      ...prevParams,
      [key]: {
        ...prevParams[key],
        isLoading: true,
      },
    }));

    ParamsService.getParams(key, payload)
      .then(({ data }) =>
        setParams((prevParams) => ({
          ...prevParams,
          [key]: {
            items: data.result,
            isLoading: false,
            hasBeenRequested: true,
          },
        }))
      )
      .catch(() => {
        setParams((prevParams) => ({
          ...prevParams,
          [key]: {
            ...prevParams[key],
            isLoading: false,
          },
        }));
      });
  };

  const contextValue = {
    params,
    requestParam,
  };

  return <ParamsContext.Provider value={contextValue}>{children}</ParamsContext.Provider>;
}

ParamsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
